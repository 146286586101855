import _ from "lodash";
import smoothscroll from "smoothscroll-polyfill";
import { waitFor } from "./mo-utils";
/**
 * Cross browser support for smooth scrolling target to position.
 */
export const smoothScrollToPosition = (target, to, duration = 500) => {
    const from = target.scrollTop;
    const diff = to - from;
    let start;
    const step = (timestamp) => {
        const time = timestamp - (start || (start = timestamp));
        const percent = Math.min(time / duration, 1);
        target.scrollTo(0, from + diff * percent);
        if (time < duration) {
            window.requestAnimationFrame(step);
        }
    };
    window.requestAnimationFrame(step);
};
/**
 * Scroll to item with highlight and smooth scrolling.
 */
let appliedSmoothscrollPolyfill = false;
export const scrollToItem = async (target, options) => {
    if (!appliedSmoothscrollPolyfill) {
        appliedSmoothscrollPolyfill = true;
        smoothscroll.polyfill();
    }
    const defaultDuration = 3000;
    options = _.defaults(options || {}, {
        highlight: defaultDuration,
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest'
    });
    target.scrollIntoView(_.pick(options, 'behavior', 'block', 'inline'));
    if (options.highlight) {
        const highlightDuration = _.isNumber(options.highlight) ? options.highlight : defaultDuration;
        await waitFor(200);
        highlightItem(target, true, highlightDuration);
    }
};
/**
 * Simply toggle `highlight-item` class and defer the detailed highlight style in components level.
 */
export const highlightItem = async (element, toggle, duration) => {
    element.classList.toggle('highlight-item', toggle);
    if (toggle && duration) {
        await waitFor(duration);
        element.classList.toggle('highlight-item', false);
    }
};
/**
 * Polyfill element.closest(selector) to get first ancestor matches the selector.
 */
export const selectParent = (element, selector) => {
    return !element.closest ? element.closest(element, selector) : searchParents(element, (parent) => parent.matches(selector));
};
/**
 * Search through element's ancestors and return ancestor whenever the `checkFunction` gets true result with it.
 *
 * @param {DomElement} element
 * @param {Function} checkFunction - Callback invoked with each ancestor passed.
 *
 * @returns {DomElement}
 */
export const searchParents = (element, checkFunction) => {
    let match;
    while (element) {
        element = element.parentElement;
        if (element && checkFunction(element)) {
            match = element;
            break;
        }
    }
    return match;
};
/**
 * Creates a MutationObserver and watches for DOM changes in specified element.
 */
export const observeElement = (element, options, callback) => {
    const observer = new MutationObserver(callback);
    observer.observe(element, options);
    return observer;
};
/**
 * Checks if provided element creates a new stacking context.
 *
 * Currently covers only 4 cases:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
 */
export const isStackingContext = (element) => {
    var _a;
    const { position, zIndex, transform } = getComputedStyle(element);
    const parent = element.parentElement;
    const isFlexItem = parent && ((_a = getComputedStyle(parent)) === null || _a === void 0 ? void 0 : _a.display) === 'flex';
    return ((position === 'absolute' || position === 'relative') && zIndex !== 'auto'
        || (position === 'fixed' || position === 'sticky')
        || (isFlexItem && zIndex !== 'auto')
        || (transform !== 'none'));
};
/**
 * For detecting if the component is loaded within an iframe and the interaction between the top
 * document is blocked by security restriction with exception e.g.`DOMException: Blocked a frame with origin site..`
 */
export const isLocationInteractionBlocked = () => {
    let blocked;
    try {
        blocked = _.isUndefined(window.top.location.search);
    }
    catch (error) {
        blocked = true;
    }
    return blocked;
};
